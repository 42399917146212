<!-- Localized -->
<template>
    <modal-content ref="add-user-modal" :showFooter="false" :max-width="480" name="add-user-modal" :title="getTheTitle" @close="$emit('closeModal')">
        <template #content>
            <div style="min-height: 250px">
                <FormulateForm class="min-h-full flex-1">
                    <div class="flex flex-col justify-between min-h-full">
                        <div class="form flex w-full flex-col flex-1">
                            <FormulateInput class="w-full" :label="$t('add_user_modal.user_name')" :placeholder="$t('add_user_modal.user_name')" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="user.username" />
                            <FormulateInput class="w-full" :label="$t('add_user_modal.email')" :placeholder="$t('add_user_modal.email')" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="user.email" :disabled="disabledEmail" :class="modalType === 'approveUser' ? 'disabled cuursor-not-allowed opacity-50' : ''"/>
                            <FormulateInput v-if="modalType === 'addUser'" class="w-full" :label="$t('add_user_modal.telephone')" :placeholder="$t('add_user_modal.telephone')" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="user.mobile" @keypress="isIntegerNumber($event)" />
                            <FormulateInput  v-if="modalType === 'addUser'" class="w-full" :label="$t('add_user_modal.position')" :placeholder="$t('add_user_modal.position')" type="text" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="user.position" />
                            <div class="">
                                <span class="text-gray-500 text-md"> {{ rolesLabel }} </span>

                                <Dropdown
                                    reposition
                                    searchable
                                    :multiple="true"
                                    :tag-placeholder="$t('add_user_modal.status')"
                                    :close-on-select="true"
                                    :placeholder="rolesLabel"
                                    :show-labels="false"
                                    id="searchstatus"
                                    class="dropdownRoles"
                                    :options="roles"
                                    ref="status"
                                    v-model="user.roles"
                                    :loading="loadingState.roles"
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#e8e8e8',
                                    }"
                                    :value="user.roles"
                                    :config="{ label: 'title', trackBy: 'id', limit: 1 }"
                                    minWidth="100%"
                                    maxWidth="80%"
                                    width="100px"
                                />
                            </div>
                            <div class="flex flex-col gap-2 mt-4">
                                <span class="text-gray-500 text-md"> {{ groupLabel }} </span>

                                <Dropdown
                                    reposition
                                    searchable
                                    :multiple="true"
                                    :placeholder="groupLabel"
                                    class="dropdownAssign"
                                    :options="groups"
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#dedede',
                                    }"
                                    :loading="loadingState.groups"
                                    v-model="user.groups"
                                    :value="user.groups"
                                    :config="{ label: 'title', trackBy: 'id', limit: 1 }"
                                    minWidth="100%"
                                    maxWidth="100%"
                                    width="100px"
                                />
                            </div>
                        </div>
                        <div class="card-actions justify-end">
                            <Button :disabled="buttonDisable" class="saveBtn" :text="submitButtonText" type="primary" @click="$emit('handleSubmit', user)" />
                        </div>
                    </div>
                </FormulateForm>
            </div>
        </template>
    </modal-content>
</template>
<script>
import modalContent from "../../modal-content";
import Dropdown from "@/components/dropdown-base";
import Button from "@shared/components/button";
import { isIntegerNumber } from "@/plugins/functions";

export default {
    name: "add-user-modal",
    components: {
        modalContent,
        Dropdown,
        Button,
    },
    props: {
        modalType: {
            type: String,
            default: 'addUser'
        },
        loadingState: {
            type: Object,
            default: () => {},
        },
        roles: {
            type: Array,
            default: () => [],
        },
        groups: {
            type: Array,
            default: () => [],
        },
        user:{
            type:Object,
            default: () => {}
        },
        rolesLabel: {
            type: String,
            default: "Assign Roles"
        },
        groupLabel: {
            type: String,
            default: "Assign Groups"
        },
        submitButtonText: {
            type: String,
            default: "Save"
        }
    },
    data() {
        return {
            // user: {
            //     username: "",
            //     email: "",
            //     roles: [],
            //     groups: [],
            //     mobile: '',
            //     position: ''
            // },
        };
    },
    mounted() {
        this.closeModal();
    },
    computed: {
        buttonDisable(){
            if(this.modalType === 'addUser') {
                let { username, email, roles, groups, mobile } = this.user;
                return !username || !email || !roles.length || !groups.length || !mobile
            } else {
                let { username, email } = this.user;
                return !username || !email
            }
           
        },
        getTheTitle() {
            return this.modalType === 'approveUser'  ?  'Approve User' : 'Add User'
        },
        disabledEmail() {
            return this.modalType === 'approveUser' ? true : false
        }
    },
    methods: {
        isIntegerNumber,
        closeModal() {
            this.$refs["add-user-modal"].hideModal();
        },
        showUserModal() {
            this.$refs["add-user-modal"].showModal();
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep {

    .dropdownRoles{
        .multiselect__content-wrapper{
            max-height :7.6rem !important;
            width:321px !important;
            min-width:0px!important;
            min-height:5rem !important;
        }
    }
    .dropdownAssign{
        .multiselect__content-wrapper{
            max-height :8rem !important;
            width:321px !important;
            min-width:0px!important;
            min-height:5rem !important;
        }
    }
}
.saveBtn{
    z-index: 999;
}
.formulate-input[data-classification="text"] input[disabled] {
  pointer-events: none;
  opacity: 0.5;
  /* Optional: Add additional styles to convey disabled state */
  background-color: #f2f2f2;
  color: #999;
  cursor: not-allowed;
}
</style>
